import React from 'react';
import CountdownTimer from '../components/Countdown/Countdown';
import ConfettiBackground from '../components/confettibackground';
import CountdownTimerWithConfettiBackground from '../components/Countdown/Countdown';

function App() {
  return (
    <div>
    {/* <ConfettiBackground/>
    <CountdownTimer/> */}
    <CountdownTimerWithConfettiBackground/>
    </div>
  );
}

export default App;
